import React from 'react'
// 1. Import useStyleConfig
import { useStyleConfig, Box } from "@chakra-ui/react"

export const Button = (props) => {
  const { size, variant, color, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("Button", { size, variant, color })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="button" sx={styles} {...rest} />
}